

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonButton,
  IonItem,
  IonInput,
} from "@ionic/vue";
import { ref } from "vue";

export default {
  props: ["user"],
  emits: ['closeUpdateForm'],
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonItem,
    IonInput,
    IonButton,
  },
  setup(props) {
    const userField = ref(props.user);
    const closeUpdateForm = () => {
      // emit("close-update-form")
    };
    // console.log(this.user)
    return {
      closeUpdateForm,
      userField,
    };
  },
};
</script>

<template>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label position="stacked">First Name</ion-label>
          <ion-input
            :value="userField.firstName"
            @input="userField.firstName = $event.target.value"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Last Name</ion-label>
          <ion-input 
            :value="userField.lastName"
            @input="userField.lastName = $event.target.value"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Email Address</ion-label>
          <ion-input 
            :value="userField.email"
            @input="userField.email = $event.target.value"
          ></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button @click="$emit('closeUpdateForm', userField)" expand="block">
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<style></style>
